import axios from 'axios'
import { headers } from './header'

export const listaSelectService = {
  // paginacion,
  getParentesco,
  getComuna,
  getAllpuntoVentaes,
}

const URL_API = `${process.env.VUE_APP_API_HOST_ADMIN}/api/v1`
const URL_API_ADMIN = `${process.env.VUE_APP_API_HOST_AUTH}/api/v1`

// async function paginacion(currentPage, catidadRegistros) {
//   const requestOptions = {
//     method: 'GET',
//     url: `${URL_API}/productos/configuracion?page=${currentPage}&perPage=${catidadRegistros}&paginado=true`,
//     headers: headers(),
//   }

//   return axios(requestOptions)
//     .then(res => {
//       const respuesta = res.data.data
//       console.log(respuesta, 'servicesss')

//       return respuesta
//     })
//     .catch(error => {
//       console.log('error ', error)
//     })
// }

async function getParentesco() {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API}/parentescos`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions).then(res => res)
}

async function getComuna() {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API}/comunas?orderBy=comu_nombre&typeOrder=ASC`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions).then(res => res)
}

async function getAllpuntoVentaes() {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_ADMIN}/sucursales`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions).then(res => res)
}
