<template>
  <div class=" mt-15 ml-15 mr-15">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-left py-7 color-head-card mb-3">
          <router-link
            to="/"
            class="ml-5 d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="40px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-0">
              Solicitud de Acceso
            </p>
            <p class="mb-0 color-card-text">
              Inserte los siguientes datos para solicitar el acceso a la aplicación
            </p>
          </v-card-text>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="registerForm"
            class="multi-col-validation"
            @submit.prevent="handleFormSubmit"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  name="nombre"
                  v-model="nombre"
                  label="Nombre"
                  dense
                  outlined
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  name="apellidos"
                  v-model="apellido"
                  label="Apellidos"
                  dense
                  outlined
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  name="rut"
                  v-model="rut"
                  v-mask="maskRut"
                  label="RUT"
                  dense
                  outlined
                  :rules="[validators.required, rutValido.esValidoNumeroRut]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  name="usuario"
                  v-model="usuario"
                  label="Usuario"
                  dense
                  outlined
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  name="correo"
                  v-model="correo"
                  label="Correo"
                  dense
                  outlined
                  :error-messages="errorMessages.email"
                  :rules="[validators.required, validators.emailValidator]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  name="codigoAs400"
                  v-model="usuarioAS"
                  label="Codigo AS-400"
                  dense
                  outlined
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
                name="cargo"
              >
                <v-select
                  name="cargo"
                  v-model="cargo"
                  :items="cargosOptions"
                  item-value="value"
                  outlined
                  dense
                  label="Cargo"
                  :rules="[validators.required]"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
                name="sucursalCodigo"
              >
                <v-select
                  name="sucursalCodigo"
                  v-model="puntoVenta"
                  :items="puntoVentaOptions"
                  item-text="nombre"
                  item-value="codigo"
                  outlined
                  dense
                  label="Punto de Venta"
                  :rules="[validators.required]"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  class="mt-6"
                  :loading="loading"
                  :disabled="loading"
                >
                  Solicitar Acceso
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Poseo cuenta de acceso
          </span>
          <router-link :to="{name:'auth-login-v1'}">
            Volver al inicio de sesión
          </router-link>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiCloseThick } from '@mdi/js'
import { required, emailValidator, passwordValidator, alphaValidator } from '@core/utils/validation'
import { ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { listaSelectService } from '../service/listasSelects'
import { maskRut, esValidoNumeroRut, cleanMaskRut } from '@/helpers/rut'

export default {
  setup() {
    // Template Refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const loading = ref(false)
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')

    const isPasswordVisible = ref(false)
    const nombre = ref('')
    const apellido = ref('')
    const rut = ref('')
    const usuario = ref('')
    const correo = ref('')
    const usuarioAS = ref('')
    const cargo = ref('')
    const tipoDocumento = ref('')
    const puntoVenta = ref('')
    const verificacionDocumento = ref('')
    const errorMessages = ref([])
    const puntoVentaOptions = ref([])

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()
      const rutFormat = cleanMaskRut(rut.value)
      if (!isFormValid) return
      loading.value = true
      const url = `${process.env.VUE_APP_API_HOST_AUTH}/api/v1/usuarios/solicitud`
      const requestOptions = {
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
          'x-mock-match-request-body': true,
          'x-hites-insurance': `${process.env.VUE_APP_APIKEY_API}`,
        },
        data: JSON.stringify({
          codigoAs400: usuarioAS.value,
          nombres: nombre.value,
          apellidos: apellido.value,
          tipoDocumento: 'rut',
          numeroDocumento: rutFormat,
          cargo: cargo.value,
          usuario: usuario.value,
          correo: correo.value,
          sucursalCodigo: puntoVenta.value,
        }),
        validateStatus: () => true,
      }
      axios(requestOptions)
        .then(response => {
          console.log(response.data.statusCode)
          if (response.data.statusCode === 400 || response.data.statusCode === 404) {
            isFormSubmittedSnackbarVisible2.value = true
            textSnackbar.value = response.data.message
            colorSnackbar.value = 'error'
            loading.value = false
          } else {
            isFormSubmittedSnackbarVisible2.value = true
            textSnackbar.value = 'Se ha enviado la solicitud de forma correcta'
            colorSnackbar.value = 'success'
            setTimeout(() => router.push('/inicio_sesion'), 3000)
          }

          return response
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          errorMessages.value = error.response.data.error
        })
    }

    return {
      maskRut,
      isPasswordVisible,
      nombre,
      apellido,
      rut,
      usuario,
      correo,
      usuarioAS,
      cargo,
      tipoDocumento,
      puntoVenta,
      verificacionDocumento,
      errorMessages,
      handleFormSubmit,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCloseThick,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logoLogin,
      registerForm,

      tipoDocumentosOptions: [
        { value: 'rut', text: 'rut' },
        { value: 'curd', text: 'curd' },
      ],
      cargosOptions: [
        { value: 'cargo1', text: 'Gerente' },
        { value: 'Jefe de crédito', text: 'Jefe de crédito' },
      ],
      puntoVentaOptions,

      isFormSubmittedSnackbarVisible2,
      textSnackbar,
      colorSnackbar,
      loading,
      rutValido: {
        esValidoNumeroRut,
      },
    }
  },
  created() {
    this.puntoDeventas()
  },
  methods: {
    puntoDeventas() {
      listaSelectService.getAllpuntoVentaes().then(resp => {
        const respuesta = resp.data.data
        respuesta.map(venta => {
          if (venta.activo == true) {
            this.puntoVentaOptions.push(venta)
          }
          console.log(venta.activo, 'venta')
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.color-head-card {
  // background-color: rgb(20 20 20 / 25%);
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2f80ed, #56ccf2); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.color-card-text {
  color: #156bb6;
}
</style>
